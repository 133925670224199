
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import SelectLocationModal from "@/components/modals/forms/SelectLocationModal.vue";

export default defineComponent({
  name: "dashboard",
  components: { SelectLocationModal },
  setup() {
    const store = useStore();

    const sportsTvGuideLink = ref<string>("");

    const sendGridLinkLoading = ref(0);

    const getGuideLink = async () => {
      let acctId = store.state.AuthModule.account.id;
      if (acctId !== undefined) {
        ApiService.get("api/v1/accounts/" + acctId + "/sports_tv_guide").then(
          (r) => {
            sportsTvGuideLink.value = r.data["sso_link"];
          }
        );
      }
    };

    const launchSendGrid = async () => {
      if (sendGridLinkLoading.value == 0) {
        sendGridLinkLoading.value++;
        let acctId = store.state.AuthModule.account.id;
        if (acctId !== undefined) {
          let r = await ApiService.get(
            "api/v1/accounts/" + acctId + "/sendgrid"
          );
          let newWin = window.open();
          if (newWin !== null) {
            newWin.document.write(r.data["sso_link"]);
            newWin.document.close();
          }
        }
        sendGridLinkLoading.value--;
      }
    };

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        getGuideLink();
      }
    );

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      getGuideLink();
    });
    return {
      sportsTvGuideLink,
      launchSendGrid,
      sendGridLinkLoading,
    };
  },
});
